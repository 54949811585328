import React, { Component } from 'react';
import { JaaSMeeting } from '@jitsi/react-sdk';
import { connect } from 'react-redux';
import ringtone from "../video-call-view/video-call-incoming/assets/audio/ringtone.mp3";
import { playRingtone } from '../../utils/videocall.util.js';
import { setVideocallInfo, setHaveModerator } from '../../redux/slices/videocallSlice';

class VideoCallJitsi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mainContent: null,
            layout: 'defaultLayout'
        };

        this.localParticipantId = null;
    }

    currentUserIsCaller() {
        const { user, videoCall } = this.props;
        return user.id === videoCall.user.id;
    }

    componentDidMount() {
        const isGroup = !!this.props.videoCall.groupCallId;
        playRingtone(!isGroup && this.currentUserIsCaller());
    }

    handleLayoutChange = (layout) => {
        this.setState({ layout });
    };

    async calculateNumberOfParticipants(externalApi) {
        try {
            const roomsInfo = await externalApi.getRoomsInfo(); 
            if (roomsInfo && roomsInfo.rooms && roomsInfo.rooms[0] && roomsInfo.rooms[0].participants) {
                return roomsInfo.rooms[0].participants.length;
            }
        } catch (error) {
            console.error('Error al obtener número de participantes:', error);
        }
        return 0;
    }

    handleVideoConferenceJoined(local) {
        this.localParticipantId = local.id;
        console.log('VIDEO CALL JITSI COMPONENT => Local user joined. ID:', local.id);
    }

    async handleParticipantJoined(participant, externalApi) {
        playRingtone(false);
        console.log("VIDEO CALL JITSI COMPONENT => Participant joined:", participant);

        const nParticipants = await this.calculateNumberOfParticipants(externalApi);
        this.props.setVideocallInfo({
            ...this.props.videoCall,
            nParticipants
        });
    }

    handleVideoConferenceLeft(participant) {
        console.log("VIDEO CALL JITSI COMPONENT => Local user left. Info:", participant);
        const iAmModerator = participant.role === 'moderator';
        console.log("VIDEO CALL JITSI COMPONENT => Local user is moderator:", iAmModerator);
        this.props.callClosed(true, iAmModerator);
    }

    async handleParticipantLeft(participant, externalApi) {
        console.log("VIDEO CALL JITSI COMPONENT => Participant left:", participant);
    
        if (participant.id === this.localParticipantId) {
            console.log("VIDEO CALL JITSI COMPONENT => Local user triggered participantLeft, ignoring...");
            return;
        }
    
        const nParticipants = await this.calculateNumberOfParticipants(externalApi);
        console.log("VIDEO CALL JITSI COMPONENT => Participantes restantes:", nParticipants);
    
        this.props.setVideocallInfo({
            ...this.props.videoCall,
            nParticipants
        });
    
        const hasModerator = await this.checkIfModeratorExists(externalApi);
    
        if (!hasModerator) {
            this.props.setHaveModerator(false);
            console.log("VIDEO CALL JITSI COMPONENT => No hay moderadores => callClosed()");
            this.props.callClosed();
            return;
        }
    
        if (nParticipants === 1) {
            console.log("VIDEO CALL JITSI COMPONENT => Only one participant left => callClosed()", this.props.videoCall);
            this.props.callClosed();
        }
    }
    
    async checkIfModeratorExists(externalApi) {
        try {
            const rooms = await externalApi.getRoomsInfo();
            if (rooms && rooms.rooms && rooms.rooms.length > 0) {
                const moderators = rooms.rooms[0].participants.filter(
                    (p) => p.role === 'moderator'
                );
                return moderators.length > 0;
            }
        } catch (error) {
            console.error('VIDEO CALL JITSI COMPONENT => Error al obtener roomsInfo:', error);
        }
        return false;
    }

    render() {
        const { appId, roomId, token, language } = this.props;
        return (
            <>
                <audio id="ringtone" src={ringtone} />
                <JaaSMeeting
                    appId={appId}
                    roomName={roomId}
                    jwt={token}
                    configOverwrite={{
                        backgroundAlpha: 0.5,
                        disableLocalVideoFlip: true,
                        disableThirdPartyRequests: true,
                        prejoinPageEnabled: false,
                        startWithAudioMuted: false,
                        startWithVideoMuted: false,
                        defaultLanguage: language
                    }}
                    interfaceConfigOverwrite={{
                        VIDEO_LAYOUT_FIT: 'nocrop',
                        MOBILE_APP_PROMO: false,
                        TILE_VIEW_MAX_COLUMNS: 4
                    }}

                    onApiReady={(externalApi) => {
                        externalApi.on('videoConferenceJoined', (local) => {
                            this.handleVideoConferenceJoined(local);
                        });
                        externalApi.on('videoConferenceLeft', (participant) => {
                            this.handleVideoConferenceLeft(participant);
                        });
                        externalApi.on('participantLeft', (participant) => {
                            this.handleParticipantLeft(participant, externalApi);
                        });
                        externalApi.on('participantJoined', (participant) => {
                            this.handleParticipantJoined(participant, externalApi);
                        });
                        externalApi.on('onReadyToClose', (data) => {
                            console.log('VIDEO CALL JITSI COMPONENT => onReadyToClose:', data);
                            this.props.callClosed();
                        });
                    }}

                    getIFrameRef={(iframeRef) => {
                        iframeRef.style.height = '100%';
                    }}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    language: state.language.language,
    user: state.user,
    videoCall: state.videocall.videocallInfo,
});

const mapDispatchToProps = (dispatch) => ({
    setVideocallInfo: (videoCallInfo) => dispatch(setVideocallInfo(videoCallInfo)),
    setHaveModerator: (haveModerator) => dispatch(setHaveModerator(haveModerator))
});
export default connect(mapStateToProps, mapDispatchToProps)(VideoCallJitsi);
