import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  videocallInfo: null,
  showVideocall: false,
  haveModerator: true,
};

const videocallSlice = createSlice({
  name: 'videcall',
  initialState,
  reducers: {
    setVideocallInfo(state, action) {
      state.videocallInfo = action.payload;
    },
    clearVideocallInfo(state) {
      state.videocallInfo = null;
    },
    setShowVideocall(state, action) {
      state.showVideocall = action.payload;
    },
    clearShowVideocall(state) {
      state.showVideocall = false;
    },
    setHaveModerator(state, action) {
      state.haveModerator = action.payload;
    },
    clearHaveModerator(state) {
      state.haveModerator = true;
    },
  },
});

export const { setVideocallInfo, clearVideocallInfo, setShowVideocall, clearShowVideocall, setHaveModerator, clearHaveModerator} = videocallSlice.actions;
export default videocallSlice.reducer;
