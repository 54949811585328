import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import { jwtDecode } from 'jwt-decode';
import logoTunstall from '../../assets/img/logo_fs_sinfondo_blanco.png';
import styles from './styles/videocallInvitation.module.scss';
import { format, differenceInMilliseconds } from 'date-fns';
import meetingEnded from '../../assets/img/meetingEnded.svg';
import meetingSoon from '../../assets/img/meetingSoon.svg';
import { connect } from 'react-redux';
import { setTenant } from '../../redux/slices/tenantSlice';
import { getNextOccurrence } from '../../services/ContentService';

class VideocallInvitation extends Component {
    constructor(props) {
        super(props);

        const urlParams = new URLSearchParams(window.location.search);
        const jwtParam = urlParams.get('jwt');
        let contentId = null;
        if (jwtParam) {
            const jwtToken = atob(decodeURIComponent(jwtParam));
            sessionStorage.setItem('accessToken', jwtToken);
            try {
                const decodedToken = jwtDecode(jwtToken);
                contentId = decodedToken.contentId;
            } catch (error) {
                console.error("Error decoding JWT:", error);
            }
        }

        const encodedCall = urlParams.get('call');
        let decodedCall = "";
        if (encodedCall) {
            try {
                decodedCall = atob(decodeURIComponent(encodedCall));
            } catch (error) {
                console.error("Error decoding call URL:", error);
            }
        }
        const encodedTenant = urlParams.get('tenant');
        let tenantName = null;
        if (encodedTenant) {
            try {
                tenantName = atob(decodeURIComponent(encodedTenant));
            } catch (error) {
                console.error("Error decoding tenant:", error);
            }
        }

        if (tenantName) {
            this.props.setTenant(tenantName);
        }

        this.state = {
            decodedCall: decodedCall,
            contentId: contentId,
            startDate: null,
            endDate: null,
            timeRemaining: null,
            isInCall: false,
            canEnter: false,
            nextOccurrence: null,
            message: null,
        };

        this.jitsiApi = null;
        this.handleInCall = this.handleInCall.bind(this);
        this.initJitsi = this.initJitsi.bind(this);
    }

    componentDidMount() {
        if (this.state.contentId) {
            this.checkOccurrence(this.state.contentId);
        } else {
            this.setState({ canEnter: true });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.canEnter && !prevState.canEnter) {
            this.initJitsi();
        }
    }

    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    }

    async checkOccurrence(contentId) {
        try {
            const response = await getNextOccurrence(contentId);
            console.log('Check occurrence response:', response);
            const now = new Date();
            if (response.canEnter) {
                const occurrence = response.occurrence;
                const startDate = new Date(occurrence.startDate);
                const endDate = new Date(occurrence.endDate);
                this.setState({
                    canEnter: true,
                    startDate: startDate,
                    endDate: endDate,
                });
            } else {
                if (response.nextOccurrence) {
                    const nextOccurrence = response.nextOccurrence;
                    const startDate = new Date(nextOccurrence.startDate);
                    const endDate = new Date(nextOccurrence.endDate);
                    this.setState({
                        canEnter: false,
                        startDate: startDate,
                        endDate: endDate,
                    });
                    this.updateTimeRemaining();
                    this.timer = setInterval(() => {
                        this.updateTimeRemaining();
                    }, 1000);
                } else {
                    this.setState({
                        canEnter: false,
                        startDate: null,
                        endDate: null,
                        message: response.message,
                    });
                }
            }
        } catch (error) {
            console.error('Error checking occurrence:', error);
        }
    }

    updateTimeRemaining() {
        const now = new Date();
        const { startDate } = this.state;

        if (startDate && now < startDate) {
            const timeDiff = differenceInMilliseconds(startDate, now);
            this.setState({ timeRemaining: timeDiff });
        } else {
            if (this.timer) {
                clearInterval(this.timer);
                this.timer = null;
            }
            this.setState({ timeRemaining: null }, () => {
                this.checkOccurrence(this.state.contentId);
            });
        }
    }

    formatNumber(number) {
        return String(number).padStart(2, '0');
    }

    handleInCall() {
        this.setState({ isInCall: true });
    }

    initJitsi() {
        if (!this.state.decodedCall || typeof window.JitsiMeetExternalAPI !== 'function') {
            return;
        }

        try {
            const jitsiUrl = new URL(this.state.decodedCall);
            const domain = jitsiUrl.hostname;
            const roomName = jitsiUrl.pathname.replace('/', '');

            const options = {
                roomName,
                parentNode: document.getElementById('jitsi-container'),
            };

            this.jitsiApi = new window.JitsiMeetExternalAPI(domain, options);

            this.jitsiApi.addEventListener('participantLeft', async (participant) => {
                console.log('Evento participantLeft =>', participant);
                try {
                    const roomsInfo = await this.jitsiApi.getRoomsInfo();
                    console.log('roomsInfo =>', roomsInfo);

                    if (roomsInfo) {
                        console.log('Revisando si quedan moderadores en la llamada...', roomsInfo);
                        const moderators = roomsInfo.rooms[0].participants.filter(
                            (p) => p.role === 'moderator'
                        );
                        if (moderators.length === 0) {
                            this.setState({ isInCall: false, canEnter: false });
                            console.log('No quedan moderadores en la llamada');
                        }
                    }
                } catch (error) {
                    console.error('Error al obtener roomsInfo:', error);
                }
            });
        } catch (error) {
            console.error('Error al inicializar JitsiMeetExternalAPI:', error);
        }
    }

    render() {
        const {
            startDate,
            endDate,
            timeRemaining,
            isInCall,
            canEnter,
            message
        } = this.state;
        const now = new Date();
        const { t } = this.props;

        if (!canEnter && !startDate && message) {
            return (
                <>
                    <div className={styles.header}>
                        <img src={logoTunstall} alt="Logo" className={styles.logo} />
                    </div>
                    <div className={styles.messageContainer}>
                        <img src={meetingEnded} alt="No Upcoming Occurrences" className={styles.meetingImage} />
                        <h1 className={styles.meetingTitle}>{t('VIDEOCALL_INVITATION.NoUpcomingOccurrences')}</h1>
                        <p>{t('VIDEOCALL_INVITATION.NoUpcomingOccurrencesMessage')}</p>
                    </div>
                </>
            );
        }

        else if (canEnter) {
            return (
                <>
                    <div className={styles.header}>
                        <img src={logoTunstall} alt="Logo" className={styles.logo} />
                    </div>
                    <div className={styles.videocallContainer}>
                        <div
                            id="jitsi-container"
                            className={styles.videocall}
                        />
                    </div>
                </>
            );
        }

        else if (startDate && now < startDate) {
            const totalSeconds = Math.floor(timeRemaining / 1000);
            const days = Math.floor(totalSeconds / (3600 * 24));
            const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
            const minutes = Math.floor((totalSeconds % 3600) / 60);
            const seconds = totalSeconds % 60;

            return (
                <>
                    <div className={styles.header}>
                        <img src={logoTunstall} alt="Logo" className={styles.logo} />
                    </div>
                    <div className={styles.messageContainer}>
                        <img src={meetingSoon} alt="Meeting Soon" className={styles.meetingImage} />
                        <h1 className={styles.meetingTitle}>{t('VIDEOCALL_INVITATION.MeetingNotStarted')}</h1>
                        <p>
                            {t('VIDEOCALL_INVITATION.MeetingStartsAt', {
                                date: format(startDate, 'dd/MM/yyyy HH:mm')
                            })}
                        </p>
                        <div className={styles.countdown}>
                            {days > 0 && (
                                <div className={styles.countdownItem}>
                                    <div className={styles.countdownNumber}>{this.formatNumber(days)}</div>
                                    <div className={styles.countdownLabel}>{t('VIDEOCALL_INVITATION.Days')}</div>
                                </div>
                            )}
                            <div className={styles.countdownItem}>
                                <div className={styles.countdownNumber}>{this.formatNumber(hours)}</div>
                                <div className={styles.countdownLabel}>{t('VIDEOCALL_INVITATION.Hours')}</div>
                            </div>
                            <div className={styles.countdownItem}>
                                <div className={styles.countdownNumber}>{this.formatNumber(minutes)}</div>
                                <div className={styles.countdownLabel}>{t('VIDEOCALL_INVITATION.Minutes')}</div>
                            </div>
                            <div className={styles.countdownItem}>
                                <div className={styles.countdownNumber}>{this.formatNumber(seconds)}</div>
                                <div className={styles.countdownLabel}>{t('VIDEOCALL_INVITATION.Seconds')}</div>
                            </div>
                        </div>
                    </div>
                </>
            );
        }

        else if (endDate && now > endDate && !isInCall) {
            return (
                <>
                    <div className={styles.header}>
                        <img src={logoTunstall} alt="Logo" className={styles.logo} />
                    </div>
                    <div className={styles.messageContainer}>
                        <img src={meetingEnded} alt="Meeting End" className={styles.meetingImage} />
                        <h1 className={styles.meetingTitle}>{t('VIDEOCALL_INVITATION.MeetingEnded')}</h1>
                        <p>
                            {t('VIDEOCALL_INVITATION.MeetingEndedAt', {
                                date: format(endDate, 'dd/MM/yyyy HH:mm')
                            })}
                        </p>
                    </div>
                </>
            );
        }

        else {
            return (
                <>
                    <div className={styles.header}>
                        <img src={logoTunstall} alt="Logo" className={styles.logo} />
                    </div>
                    <div className={styles.messageContainer}>
                        <img src={meetingEnded} alt="Meeting End" className={styles.meetingImage} />
                        <h1 className={styles.meetingTitle}>{t('VIDEOCALL_INVITATION.MeetingEnded')}</h1>
                    </div>
                </>
            );
        }
    }
}

const mapDispatchToProps = (dispatch) => ({
    setTenant: (tenant) => dispatch(setTenant(tenant)),
});

export default connect(null, mapDispatchToProps)(withTranslation()(VideocallInvitation));
